import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalMessageService, isNotUndefined, OCC_USER_ID_CURRENT } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { User } from '@spartacus/user/account/root';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';


@Injectable()
export class CustomUpdateProfileComponentService extends UpdateProfileComponentService {
  constructor(
    protected userProfile: UserProfileFacade,
    protected globalMessageService: GlobalMessageService,
    protected userAccount: UserAccountFacade,
  ) {
    super(userProfile, globalMessageService);
  }
  protected user$: Observable<User | undefined> = this.userAccount.getById(OCC_USER_ID_CURRENT);

  exclusiveRole$ = this.user$.pipe(
    filter(user => isNotUndefined(user?.exclusiveRole)),
    map(user => String(user.exclusiveRole))
  );

  hasDocumentIdentifier$ = this.user$.pipe(
    map(user => Boolean(user?.documentIdentifier))
  );

  applyValues$ = this.user$.pipe(
    filter(isNotUndefined),
    tap(user => {
      if (!user.documentIdentifier) {
        user.documentIdentifier = sessionStorage.getItem('dCode');
      }
      this.form.patchValue(user);
      return of(user);
    })
  );

  form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    uid: new FormControl(''),
    exclusiveRole: new FormControl(''),
    supportedOnBehalfOfExclusiveRole: new FormControl(''),
    documentIdentifier: new FormControl('',
      [
        Validators.required,
        CustomValidators.documentIdentifier('exclusiveRole', 'defaultAddress.country.isocode')
      ]),
    defaultAddress: new FormGroup({
      phone: new FormControl('', [Validators.required, CustomValidators.phoneValidator]),
      line1: new FormControl('', [Validators.required, Validators.maxLength(35)]),
      line2: new FormControl('', [Validators.required, Validators.maxLength(35)]),
      town: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      postalCode: new FormControl('', [Validators.required, Validators.maxLength(12), CustomValidators.postalCode('country.isocode')]),
      email: new FormControl('', [Validators.required, CustomValidators.emailValidator]),
      country: new FormGroup({
        isocode: new FormControl(null, Validators.required),
      }),
      region: new FormGroup({
        isocode: new FormControl(null, Validators.required),
      })
    }),
  });


  protected onSuccess(): void {
    this.busy$.next(false);
    this.isUpdating$ = of(true);
  }


  /**
   * Updates the user's details and handles the UI.
   */

  newUpdateProfile(callback: any): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }

    this.busy$.next(true);
    this.userProfile.update({
      ...this.form.value,
      defaultAddress: {
        ...this.form.get('defaultAddress').value,
        firstName: this.form.get('name').value,
        lastName: '',
        email: this.form.get('uid').value,

      }
    })
      .subscribe({
        next: () => {
          this.onSuccess()
          callback()
        },
        error: (error: Error) => {
          this.onError(error)
          this.form.get('documentIdentifier').setErrors({
            'nifError': true
          })
          return
        },
      });
  }
}
